<template>
  <div>
    <van-nav-bar
      title="投保"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <div class="x-table">基本信息：</div>

      <van-field
        v-model="postForm.insureStartDate"
        name="insureStartDate"
        readonly
        scroll-to-error
        :colon="true"
        label-width="120px"
        label-align="left"
        input-align="right"
        label="起保日期"
        placeholder="请选择日期"
        :rules="[{ required: true }]"
      >
      </van-field>

      <!-- <van-field
        v-model="postForm.InsurancePeriod"
        name="InsurancePeriod"
        readonly
        scroll-to-error
        :colon="true"
        label-width="120px"
        label-align="left"
        input-align="right"
        label="保险期限"
        placeholder="请选择期限"
        :rules="[{ required: true }]"
      >
      </van-field> -->

      <van-field
        v-model="postForm.hospitalText"
        type="search"
        name="hospitalName"
        scroll-to-error
        :colon="true"
        label-align="left"
        label-width="120px"
        input-align="right"
        label="医院名称"
        placeholder="请选择医院名称"
        :rules="[{ required: true }]"
        required
        clearable
        @blur="onSearchClick"
      >
      </van-field>

      <van-field
        v-model="postForm.dept"
        name="dept"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="科室"
        placeholder="请输入科室"
        clearable
        :maxlength="50"
      ></van-field>

      <van-field
        v-model="postForm.groupStr"
        name="groupStr"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="分组"
        placeholder="请输入分组"
        clearable
        :maxlength="50"
      ></van-field>

      <van-field
        v-model="postForm.operationOrderDate"
        name="operationOrderDate"
        @click="showCalendarOrderDate = true"
        readonly
        clickable
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="手术预定日期"
        placeholder="请选择预定日期"
        :rules="[{ required: true }]"
        required
      ></van-field>

      <van-field
        v-model="postForm.operationLevelName"
        name="operationLevel"
        @click="showCalendarLevel = true"
        readonly
        clickable
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="手术级别"
        placeholder="请选择级别"
        :rules="[{ required: true }]"
        required
      ></van-field>
      <!-- 
      <van-field
        v-model="postForm.ageName"
        name="age"
        @click="showCalendarAge = true"
        readonly
        clickable
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="患者年龄"
        placeholder="请选患者年龄"
        :rules="[{ required: true }]"
        required
      ></van-field> -->

      <van-field
        v-model="postForm.phone"
        type="number"
        name="phone"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[{ required: true }, rules.phoneLength, rules.phoneFormat]"
        required
        clearable
        :maxlength="11"
        @input="userInpurtPhone"
      ></van-field>

      <div class="x-table">投保人信息：</div>

      <van-field
        v-model="postForm.certificateTypeName"
        name="certificateType"
        @click="showCalendarCertificateType = true"
        readonly
        clickable
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="证件类型"
        placeholder="请选择证件类型"
        :rules="[{ required: true }]"
        required
      ></van-field>

      <!-- <van-field name="uploader" label="证件上传" v-if="isCodeType">
        <template #input>
          <div>
            <van-uploader
              multiple
              :max-count="1"
              :before-read="beforeRead"
              v-model="fileList"
              :after-read="afterRead"
              @oversize="onOversize"
            />
            <div class="x-msg">身份证人像面</div>
          </div>
        </template>
      </van-field> -->

      <van-field
        v-model="postForm.name"
        name="name"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true }]"
        required
        clearable
        :maxlength="20"
      ></van-field>

      <van-field
        v-model="postForm.insured"
        name="insured"
        readonly
        scroll-to-error
        :colon="true"
        label-width="120px"
        label-align="left"
        input-align="right"
        label="投保人性质"
        placeholder="请选择投保人性质"
      >
      </van-field>

      <van-field
        v-model="postForm.certificateNo"
        name="certificateNo"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="证件号码"
        placeholder="请输入证件号码"
        :rules="[{ required: true }, rules.idCardLength, rules.idCardFormat]"
        required
        clearable
        :maxlength="18"
        @blur="verifyIDCard('certificateNo')"
      ></van-field>

      <van-field
        v-show="!isCodeType"
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="性别"
        placeholder="性别"
        :rules="[{ required: true }]"
        required
      >
        <template #input>
          <van-radio-group
            v-model="postForm.sex"
            name="sex"
            direction="horizontal"
          >
            <van-radio name="男">男</van-radio>
            <van-radio name="女">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-show="!isCodeType"
        v-model="postForm.insureAgeName"
        name="insureAge"
        @click="showCalendarInsureAge = true"
        readonly
        clickable
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="年龄"
        placeholder="请选年龄"
      ></van-field>

      <van-field
        v-show="!isCodeType"
        v-model="postForm.birthDay"
        name="birthDay"
        @click="showCalendarBirthDay = true"
        readonly
        clickable
        label-width="120px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="right"
        label="出生日期"
        placeholder="请选择出生日期"
        :rules="[{ required: true }]"
        required
      ></van-field>

      <!-- <van-field
        v-model="postForm.phone"
        type="number"
        name="phone"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[{ required: true }, rules.phoneLength, rules.phoneFormat]"
        required
        clearable
        :maxlength="11"
      ></van-field> -->

      <!-- <van-field
        v-model="postForm.telPhone"
        type="number"
        name="telPhone"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="固定电话"
        placeholder="请输入固定电话"
        clearable
        :maxlength="12"
      ></van-field> -->

      <!-- <van-field
        v-model="postForm.address"
        name="address"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="居住地址"
        placeholder="请输入居住地址"
        :rules="[{ required: true }]"
        required
        clearable
      >
      </van-field> -->

      <!-- <van-field
        v-model="postForm.postCode"
        name="postCode"
        scroll-to-error
        label-width="120px"
        label-align="left"
        input-align="right"
        label="邮编"
        placeholder="请输入邮编"
        :rules="[{ required: true }]"
        required
        clearable
        :maxlength="6"
        type="number"
      >
      </van-field> -->

      <!-- <van-field v-model="postForm.email" name="email" scroll-to-error label-width="120px" label-align="left" input-align="right" label="电子邮件" placeholder="请输电子邮件" clearable :maxlength="50">
      </van-field> -->

      <div class="x-table">被保人信息：</div>

      <van-field
        v-model="postForm.relation"
        name="relation"
        label-width="90px"
        scroll-to-error
        :colon="true"
        label-align="left"
        input-align="left"
        label="与投保人关系"
      >
        <template #input>
          <van-button
            class="x-rel-button"
            :type="postForm.relation == item.value ? 'blue' : 'default'"
            size="small"
            v-for="(item, index) in operationRelation"
            :key="index"
            @click="onClickeRelation(item)"
            native-type="button"
            >{{ item.label }}
          </van-button>
        </template>
      </van-field>

      <div v-if="postForm.relation !== '601005'">
        <van-field
          v-model="postForm.insuredCertificateTypeName"
          name="insuredCertificateType"
          @click="showCalendarInsuredCertificateType = true"
          readonly
          clickable
          label-width="120px"
          scroll-to-error
          :colon="true"
          label-align="left"
          input-align="right"
          label="证件类型"
          placeholder="请选择证件类型"
          :rules="[{ required: true }]"
          required
        ></van-field>

        <!-- <van-field name="uploader" label="证件上传" v-if="isCodeTypeSub">
          <template #input>
            <div>
              <van-uploader
                multiple
                :max-count="1"
                :before-read="beforeRead"
                v-model="fileListSub"
                :after-read="afterReadSub"
                @oversize="onOversize"
              />
              <div class="x-msg">身份证人像面</div>
            </div>
          </template>
        </van-field> -->

        <van-field
          v-model="postForm.insuredName"
          name="insuredName"
          scroll-to-error
          label-width="120px"
          label-align="left"
          input-align="right"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true }]"
          required
          clearable
          :maxlength="20"
        >
        </van-field>

        <van-field
          v-model="postForm.insured"
          name="insured"
          readonly
          scroll-to-error
          :colon="true"
          label-width="120px"
          label-align="left"
          input-align="right"
          label="投保人性质"
          placeholder="请选择投保人性质"
        >
        </van-field>

        <van-field
          v-model="postForm.insuredCertificateNo"
          name="insuredCertificateNo"
          scroll-to-error
          label-width="120px"
          label-align="left"
          input-align="right"
          label="证件号码"
          placeholder="请输入证件号码"
          :rules="[{ required: true }, rules.idCardLength, rules.idCardFormat]"
          required
          clearable
          :maxlength="18"
          @blur="verifyIDCard('insuredCertificateNo')"
        ></van-field>

        <van-field
          v-show="!isCodeTypeSub"
          label-width="120px"
          scroll-to-error
          :colon="true"
          label-align="left"
          input-align="right"
          label="性别"
          placeholder="性别"
          :rules="[{ required: true }]"
          required
        >
          <template #input>
            <van-radio-group
              v-model="postForm.insuredSex"
              name="insuredSex"
              direction="horizontal"
            >
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          v-show="!isCodeTypeSub"
          v-model="postForm.insuredAgeName"
          name="insuredAge"
          @click="showCalendarInsuredAge = true"
          readonly
          clickable
          label-width="120px"
          scroll-to-error
          :colon="true"
          label-align="left"
          input-align="right"
          label="年龄"
          placeholder="请选年龄"
        ></van-field>

        <van-field
          v-show="!isCodeTypeSub"
          v-model="postForm.insuredBirthDay"
          name="insuredBirthDay"
          @click="showCalendarInsuredBirthDay = true"
          readonly
          clickable
          label-width="120px"
          scroll-to-error
          :colon="true"
          label-align="left"
          input-align="right"
          label="出生日期"
          placeholder="请选择出生日期"
          :rules="[{ required: true }]"
          required
        ></van-field>

        <!-- <van-field
          v-model="postForm.insuredPhone"
          type="number"
          name="insuredPhone"
          scroll-to-error
          label-width="120px"
          label-align="left"
          input-align="right"
          label="手机号码"
          placeholder="请输入手机号码"
          :rules="[{ required: true }, rules.phoneLength, rules.phoneFormat]"
          required
          clearable
          :maxlength="11"
        ></van-field> -->

        <!-- <van-field
          v-model="postForm.insuredTelPhone"
          type="number"
          name="insuredTelPhone"
          scroll-to-error
          label-width="120px"
          label-align="left"
          input-align="right"
          label="固定电话"
          placeholder="请输入固定电话"
          clearable
          :maxlength="12"
        ></van-field> -->

        <!-- <van-field
          v-model="postForm.insuredAddress"
          name="insuredAddress"
          scroll-to-error
          label-width="120px"
          label-align="left"
          input-align="right"
          label="居住地址"
          placeholder="请输入居住地址"
          :rules="[{ required: true }]"
          required
          clearable
        >
        </van-field> -->

        <!-- <van-field
          v-model="postForm.insuredPostCode"
          name="insuredPostCode"
          scroll-to-error
          label-width="120px"
          label-align="left"
          input-align="right"
          label="邮编"
          placeholder="请输入邮编"
          :rules="[{ required: true }]"
          required
          clearable
          :maxlength="6"
          type="number"
        >
        </van-field> -->
      </div>
      <div class="x-submit-dom">
        <!-- loading type="info" loading-text="加载中..." -->
        <van-button
          :class="!isSubmit ? 'x-submit' : 'x-submit-wait'"
          :loading="isSubmit"
          loading-text="提交中..."
          round
          block
          native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>

    <!-- 弹窗选择 -->
    <!-- 选择医院 -->
    <van-popup
      v-model="showCalendarHospitalName"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '80%' }"
    >
      <van-picker
        :columns="operationHospitalNameList"
        @confirm="onConfirmHospitalName"
        @cancel="showCalendarHospitalName = false"
        item-height="40"
        visible-item-count="12"
        title="医院"
        :show-toolbar="true"
        value-key="hospitalName"
      />
    </van-popup>
    <!-- 请选择预定日期 -->
    <van-calendar
      v-model="showCalendarOrderDate"
      title="预定日期"
      @confirm="onConfirmOperationOrderDate"
      lazy-render
      color="#1989fa"
      @select="onConfirmOperationOrderDate"
      :show-confirm="false"
    />
    <!-- 手术级别 -->
    <van-popup
      v-model="showCalendarLevel"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="operationLevelOptions"
        @confirm="onConfirmOperationLevel"
        @cancel="showCalendarLevel = false"
        item-height="40"
        visible-item-count="5"
        title="手术级别"
        :show-toolbar="true"
        value-key="showName"
      />
    </van-popup>
    <!-- 患者年龄 -->
    <van-popup
      v-model="showCalendarAge"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="operationAge"
        @confirm="onConfirmAge"
        @cancel="showCalendarAge = false"
        item-height="40"
        visible-item-count="5"
        title="患者年龄"
        :show-toolbar="true"
        value-key="label"
      />
    </van-popup>
    <!-- 证件类型 -->
    <van-popup
      v-model="showCalendarCertificateType"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="operationCertificateType"
        @confirm="onConfirmCertificateType"
        @cancel="showCalendarCertificateType = false"
        item-height="40"
        visible-item-count="5"
        title="证件类型"
        :show-toolbar="true"
        value-key="label"
      />
    </van-popup>
    <!-- 投保人年龄 -->
    <van-popup
      v-model="showCalendarInsureAge"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="operationInsureAge"
        @confirm="onConfirmInsureAge"
        @cancel="showCalendarInsureAge = false"
        item-height="40"
        visible-item-count="5"
        title="投保人年龄"
        :show-toolbar="true"
        value-key="label"
      />
    </van-popup>
    <!-- 被保人年龄 -->
    <van-popup
      v-model="showCalendarInsuredAge"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="operationAge"
        @confirm="onConfirmInsuredAge"
        @cancel="showCalendarInsuredAge = false"
        item-height="40"
        visible-item-count="5"
        title="投保人年龄"
        :show-toolbar="true"
        value-key="label"
      />
    </van-popup>
    <!-- 请选出生日期 -->
    <van-popup
      v-model="showCalendarBirthDay"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        type="date"
        title="选择年月日"
        v-model="currentDate"
        :min-date="initDate"
        :max-date="newDate"
        :formatter="formatter"
        @confirm="onConfirmOperationBirthDay"
        @cancel="showCalendarBirthDay = false"
      />
    </van-popup>
    <!-- 被保人出生日期 -->
    <van-popup
      v-model="showCalendarInsuredBirthDay"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        type="date"
        title="选择年月日"
        v-model="currentDateInsured"
        :min-date="initDate"
        :max-date="newDate"
        :formatter="formatter"
        @confirm="onConfirmOperationInsuredBirthDay"
        @cancel="showCalendarInsuredBirthDay = false"
      />
    </van-popup>
    <!-- 证件类型 -->
    <van-popup
      v-model="showCalendarInsuredCertificateType"
      position="bottom"
      round
      safe-area-inset-bottom
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="operationCertificateType"
        @confirm="onConfirmInsuredCertificateType"
        @cancel="showCalendarInsuredCertificateType = false"
        item-height="40"
        visible-item-count="5"
        title="证件类型"
        :show-toolbar="true"
        value-key="label"
      />
    </van-popup>

    <!-- 提交等待 -->
    <van-overlay :show="isSubmit">
      <div class="wrapper">
        <van-loading size="40px" color="#FFFFFF">提交中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { IdCard } from "../util/publicFun";
import rules from "../util/rules";
import { baiduError } from "../util/errorCode";
import lrz from "lrz";
import { getSession, setSession } from "../util/sessionStorage";

export default {
  components: {},
  computed: {},
  data() {
    return {
      rules: rules,
      initDate: new Date(1900, 0, 1),
      newDate: new Date(),
      currentDate: new Date(),
      currentDateInsured: new Date(),

      showCalendarHospitalName: false, //医院名称
      showCalendarOrderDate: false, //手上预定日期
      showCalendarLevel: false, //手术级别
      showCalendarAge: false, //患者年龄
      showCalendarCertificateType: false, //证件类型
      showCalendarInsureAge: false, //性别弹框
      showCalendarBirthDay: false, //出生日期
      showCalendarInsuredCertificateType: false, //被保人身份类型
      showCalendarInsuredAge: false, //被保人年龄
      showCalendarInsuredBirthDay: false, //出生日期

      isSubmit: false, //提交状态
      isCodeType: false, //证件类型投保人
      isCodeTypeSub: false, //证件类型被保人

      postForm: {
        // 基本信息
        insureStartDate: "", //起保日期
        InsurancePeriod: "2个月", //保险期限
        hospitalName: "", //医院ID
        hospitalText: "", //医院名称
        operationOrderDate: "", //手上预定日期：
        operationLevel: "", //手术级别
        operationLevelName: "", //手术级别名称
        age: 0, //患者年龄
        ageName: "", //患者年龄名称
        dept: "", //科室
        groupStr: "", //分组

        // 投保人
        name: "", //姓名
        insured: "自然人", //投保人性质
        certificateType: "", //证件类型
        certificateTypeName: "", //证件类型名称
        certificateNo: "", //证件号
        sex: "男", //性别
        insureAge: 0, //年龄
        insureAgeName: "", //年龄名称
        birthDay: "", //出生日期
        phone: "", //手机号
        telPhone: "", //固定电话
        address: "", //居住地址
        postCode: "", //邮编号
        email: "", //电子邮件

        //被保人信息
        relation: "601004", //与保人关系
        insuredName: "", //姓名
        insuredCertificateType: "", //证件类型
        insuredCertificateTypeName: "", //证件类型名称
        insuredCertificateNo: "", //证件号
        insuredSex: "男", //性别
        insuredAge: 0, //年龄
        insuredAgeName: "", //年龄名称
        insuredBirthDay: "", //出生日期
        insuredPhone: "",
        insuredTelPhone: "",
        insuredAddress: "",
        insuredPostCode: "",

        feeId: "",
        plantId: "",
        typeId: "",
        invitationCode: "",
      },

      formData: {
        insureStartDate: "",
        hospitalName: "",
        operationOrderDate: "",
        name: "",
        operationLevel: "",
        age: 0,
        dept: "",
        groupStr: "",
        certificateType: "",
        certificateNo: "",
        sex: "",
        insureAge: 0,
        birthDay: "",
        phone: "",
        telPhone: "",
        address: "",
        postCode: "",
        insuredName: "",
        insuredCertificateType: "",
        insuredCertificateNo: "",
        insuredSex: "",
        insuredAge: 0,
        insuredBirthDay: "",
        insuredPhone: "",
        insuredTelPhone: "",
        insuredAddress: "",
        insuredPostCode: "",
        relation: "601004",
        feeId: "",
        plantId: "",
        typeId: "",
        invitationCode: "",
      },

      fileList: [], //图片投保人
      fileListSub: [], //图片被投保

      operationHospitalNameList: [], //医院名称
      operationLevelOptions: [], //手术级别列表
      operationAge: [], //获取年龄列表
      //证件类型列表
      operationCertificateType: [
        {
          value: "111",
          label: "居民身份证",
        },
        {
          value: "112",
          label: "户口簿",
        },
        {
          value: "113",
          label: "军人证",
        },
        {
          value: "114",
          label: "护照",
        },
        {
          value: "115",
          label: "机动车驾驶证",
        },
        {
          value: "116",
          label: "港澳通行证",
        },
        {
          value: "117",
          label: "大陆居民往来台湾通行证",
        },
        {
          value: "118",
          label: "港澳居民来往内地通行证",
        },
        {
          value: "119",
          label: "台湾居民来往大陆通行证",
        },
        {
          value: "120",
          label: "外国人永久居留身份证",
        },
        {
          value: "999",
          label: "其他",
        },
      ],
      operationInsureAge: [],
      operationRelation: [
        { value: "601005", label: "本人" },
        { value: "601004", label: "配偶" },
        { value: "601003", label: "父母" },
        { value: "601002", label: "子女" },
        { value: "601008", label: "其它" },
      ],
    };
  },
  methods: {
    onOversize(file) {
      this.$Dialog.alert({ message: "上传图片不能超过4MB" });
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== "image/jpeg" && file.type != "image/png") {
        return false;
      }
      return true;
    },

    // 图片上传
    async afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let img = await lrz(file.file, { width: 1000, quality: 0.8 });
      this.$api.getImgCodeID(img.file).then((res) => {
        if (res.code != 1000) {
          file.status = "failed";
          file.message = "识别失败...";
          this.$Dialog.alert({ message: res.message || "服务异常" });
          return;
        }

        if (res.data.error_code) {
          file.status = "failed";
          file.message = "识别失败...";
          let error = baiduError[res.data.error_code];
          this.$Dialog.alert({ message: error || "服务异常" });
          return;
        }

        if (res.data.image_status != "normal") {
          file.status = "failed";
          file.message = "识别失败...";
          this.$Dialog.alert({ message: "识别失败..." });
          return;
        }

        let data = res.data.words_result;
        file.status = "done";
        file.message = "识别成功";

        console.log("图片识别结果", data);
        this.postForm.name = data["姓名"].words;
        this.postForm.certificateNo = data["公民身份号码"].words;
        this.postForm.sex = data["性别"].words;
        this.verifyIDCard("certificateNo");
      });
    },

    // 图片上传被保人
    async afterReadSub(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let img = await lrz(file.file, { width: 1000, quality: 0.8 });
      this.$api.getImgCodeID(img.file).then((res) => {
        if (res.code != 1000) {
          file.status = "failed";
          file.message = "识别失败...";
          this.$Dialog.alert({ message: res.message || "服务异常" });
          return;
        }

        if (res.data.error_code) {
          file.status = "failed";
          file.message = "识别失败...";
          let error = baiduError[res.data.error_code];
          this.$Dialog.alert({ message: error || "服务异常" });
          return;
        }

        if (res.data.image_status != "normal") {
          file.status = "failed";
          file.message = "识别失败...";
          this.$Dialog.alert({ message: "识别失败..." });
          return;
        }

        let data = res.data.words_result;
        file.status = "done";
        file.message = "识别成功";
        console.log("图片识别结果", data);
        this.postForm.insuredName = data["姓名"].words;
        this.postForm.insuredCertificateNo = data["公民身份号码"].words;
        this.postForm.insuredSex = data["性别"].words;
        this.verifyIDCard("insuredCertificateNo");
      });
    },

    // 输入手机号
    userInpurtPhone(e) {
      this.postForm.phone = e;
      this.postForm.insuredPhone = e;
    },

    onClickLeft() {
      this.$router.go(-1);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    // 初始化方法
    initData() {
      // 初始化时间
      this.postForm.insureStartDate = this.dayjs().format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.postForm.feeId = getSession("feeId");
      this.postForm.plantId = getSession("plantId");
      this.postForm.typeId = getSession("typeId");
      this.postForm.invitationCode = getSession("codeId");
      // 初始化年龄
      let list = [];
      for (var i = 0; i <= 100; i++) {
        list.push({ value: i, label: i + "岁" });
      }
      this.operationInsureAge = list;
    },

    //表单提交
    async onSubmit(e) {
      if (this.isSubmit) {
        return;
      }
      console.log("表单==>>", e);
      if (this.postForm.relation === "601005") {
        this.postForm.insuredName = this.postForm.name;
        this.postForm.insuredCertificateType = this.postForm.certificateType;
        this.postForm.insuredCertificateNo = this.postForm.certificateNo;
        this.postForm.insuredSex = this.postForm.sex;
        this.postForm.insuredAge = this.postForm.insureAge;
        this.postForm.insuredBirthDay = this.postForm.birthDay;
        this.postForm.insuredPhone = this.postForm.phone;
        this.postForm.insuredTelPhone = this.postForm.telPhone;
        this.postForm.insuredAddress = this.postForm.address;
        this.postForm.insuredPostCode = this.postForm.postCode;
      }
      this.postForm.age = this.postForm.insuredAge;

      for (let key in this.formData) {
        this.formData[key] = this.postForm[key];
      }

      let formData = Object.assign({}, this.formData);
      formData["domId"] = getSession("UUID");

      // 判断参数医院
      if (!formData["hospitalName"]) {
        Dialog.alert({ message: "请选择医院" });
        return;
      }

      //判断手术级别
      if (!formData["operationLevel"]) {
        Dialog.alert({ message: "请选择手术级别" });
        return;
      }

      // formData["domStr"] = JSON.stringify(this.recordFun.operationServe());
      this.isSubmit = true;
      // 保存视频
      console.log("录制视频");
      await this.recordFun.operationServe();

      console.log("提交数据==>>", formData);
      this.$api
        .postInsurance(formData)
        .then((res) => {
          this.isSubmit = false;
          console.log("提交表单==>>", res);
          if (res.code != 1000) {
            Dialog.alert({ message: res.message || "服务异常" });
            this.isSubmit = false;
            return;
          }
          let rs = res.data;

          if (rs.success === true) {
            window.location.href = rs.payUrl;
          } else {
            Dialog.alert({ message: rs.errorMessage || "服务异常" });
          }
        })
        .catch(() => {
          this.isSubmit = false;
        });
    },

    // 验证身份证
    verifyIDCard(type) {
      let cardNo = this.postForm[type];
      if (cardNo.length != 18) {
        console.log("输入的身份证有误");
        return;
      }
      let sex = IdCard(cardNo, 2);
      let insureAge = IdCard(cardNo, 3);
      let date = IdCard(cardNo, 1);

      if (type == "certificateNo") {
        this.postForm.sex = sex;
        this.postForm.insureAge = insureAge;
        this.postForm.insureAgeName = insureAge + "岁";
        // year, month, day
        this.postForm.birthDay = `${date.year}-${date.month}-${date.day}`;
      }
      if (type == "insuredCertificateNo") {
        this.postForm.insuredSex = sex;
        this.postForm.insuredAge = insureAge;
        this.postForm.insuredAgeName = insureAge + "岁";
        // year, month, day
        this.postForm.insuredBirthDay = `${date.year}-${date.month}-${date.day}`;
      }
    },

    // 搜索医院
    onSearchClick(e) {
      this.getHospital();
    },
    // 选择医院
    onConfirmHospitalName(e) {
      this.showCalendarHospitalName = false;
      this.postForm.hospitalText = e.hospitalName || "";
      this.postForm.hospitalName = e.hospitalName || "";

      // hospitalName: "", //医院ID
      // hospitalText: "", //医院名称

      this.postForm.address = e.hospitalName || "";
      this.postForm.insuredAddress = e.hospitalName || "";

      this.postForm.insuredPostCode = e.postCode || "";
      this.postForm.postCode = e.postCode || "";
    },

    // 请选择预定日期
    onConfirmOperationOrderDate(date) {
      this.postForm.operationOrderDate = this.dayjs(date.getTime()).format(
        "YYYY-MM-DD"
      );
      this.showCalendarOrderDate = false;
    },

    // 选择手术级别
    onConfirmOperationLevel(e) {
      this.showCalendarLevel = false;
      this.postForm.operationLevel = e.id || "";
      this.postForm.operationLevelName = e.showName || "";
    },

    // 患者年龄
    onConfirmAge(e) {
      this.showCalendarAge = false;
      this.postForm.age = e.value || "";
      this.postForm.ageName = e.label || "";
    },

    // 证件类型
    onConfirmCertificateType(e) {
      this.showCalendarCertificateType = false;
      this.postForm.certificateType = e.value || "";
      this.postForm.certificateTypeName = e.label || "";
      if (this.postForm.certificateType === "111") {
        this.isCodeType = true;
      } else {
        this.isCodeType = false;
      }
    },

    // 被保人证件类型
    onConfirmInsuredCertificateType(e) {
      this.showCalendarInsuredCertificateType = false;
      this.postForm.insuredCertificateType = e.value || "";
      this.postForm.insuredCertificateTypeName = e.label || "";
      if (this.postForm.insuredCertificateType === "111") {
        this.isCodeTypeSub = true;
      } else {
        this.isCodeTypeSub = false;
      }
    },

    // 投保人年龄
    onConfirmInsureAge(e) {
      this.showCalendarInsureAge = false;
      this.postForm.insureAge = e.value || "";
      this.postForm.insureAgeName = e.label || "";
    },

    // 投保人年龄
    onConfirmInsuredAge(e) {
      this.showCalendarInsuredAge = false;
      this.postForm.insuredAge = e.value || "";
      this.postForm.insuredAgeName = e.label || "";
    },

    // 出生日期
    onConfirmOperationBirthDay(date) {
      this.postForm.birthDay = this.dayjs(date.getTime()).format("YYYY-MM-DD");
      this.showCalendarBirthDay = false;
    },

    // 被保人出生日期
    onConfirmOperationInsuredBirthDay(date) {
      this.postForm.insuredBirthDay = this.dayjs(date.getTime()).format(
        "YYYY-MM-DD"
      );
      this.showCalendarInsuredBirthDay = false;
    },

    // 选择关系
    onClickeRelation(item) {
      this.postForm.relation = item.value;
      console.log(item);
    },

    // 获取手术级别
    getOperationLevel() {
      this.$api.getOperationLevel({ id: this.$route.params.id }).then((res) => {
        console.log("获取手术级别==>>", res);
        if (res.code != 1000) {
          this.operationLevelOptions = [];
          return;
        }
        this.operationLevelOptions = res.data;
      });
    },

    // 获取年龄限制
    getAgeRange() {
      this.$api.getAgeRange({ id: this.$route.params.id }).then((res) => {
        console.log("获取年龄限制==>>", res);
        if (res.code != 1000) {
          this.operationAge = [];
          return;
        }
        let list = [];
        for (var i = res.data.minAge; i <= res.data.maxAge; i++) {
          list.push({ value: i, label: i + "岁" });
        }
        this.operationAge = list;
      });
    },

    // 获取医院列表
    getHospital() {
      let key = this.postForm.hospitalText;

      if (!key) {
        return;
      }
      this.$api.getHospital({ key: key }).then((res) => {
        // this.postForm.hospitalText = "";
        this.postForm.hospitalName = "";

        console.log("获取医院列表", res);
        if (res.code != 1000) {
          this.operationHospitalNameList = [];
          return;
        }
        if (res.data.length <= 0) {
          Dialog.alert({ message: "未搜索到相关医院！" });
          return;
        }
        this.showCalendarHospitalName = true;
        this.operationHospitalNameList = res.data;
      });
    },
  },
  mounted() {
    // 置顶
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;

    document.title = "投保";
    this.initData();
    this.getOperationLevel();
    this.getAgeRange();
  },
};
</script>
<style lang="less" scoped>
.x-table {
  background-color: #f8f8f8;
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
  padding: 16px;
}
.x-rel-button {
  padding: 3px 6px;
  margin: 0px 3px;
}
.van-button--blue {
  background-color: #2c70f5;
  border: 1px solid #2c70f5;
  color: white;
}
.x-submit-dom {
  padding-top: 40px;
  padding-bottom: 50px;
}
.x-submit {
  width: 252px;
  height: 44px;
  background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin: auto;
  border: none;
}

.x-submit-wait {
  width: 252px;
  height: 44px;
  background: #aaaaaa;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin: auto;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.x-msg {
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
}
</style>
